import { QueryString } from '../http';
import { toUnderscoreCase } from 'lib/formatters/strings';
import { FilterOperators, LogicalOperators } from 'lib/generated_constants/participant_search';

export function transformOperatorKeysAndValues(obj) {
  const convertKey = (key) => {
    if (key.startsWith('populationAttribute')) {
      const attributeType = key.replace('populationAttribute', '').toLowerCase();
      return `population_attribute__${attributeType}`;
    }

    return toUnderscoreCase(key);
  };


  if (Array.isArray(obj)) {
    return obj.map(transformOperatorKeysAndValues);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const transformedKey = convertKey(key);
      const value = obj[key];

      acc[transformedKey] = transformOperatorKeysAndValues(value);

      return acc;
    }, {});
  }

  return obj;
}

export function getOperatorKeys(filters) {
  if (!filters || typeof filters !== 'object') {
    return [];
  }

  const objectKeys = Object.keys(filters);

  if (!hasTopLevelOperator(objectKeys)) {
    return objectKeys;
  }

  const keys = new Set();

  function processObject(obj) {
    if (Array.isArray(obj)) {
      obj.forEach(item => processObject(item));
      return;
    }

    if (typeof obj === 'object' && obj !== null) {
      for (const [key, value] of Object.entries(obj)) {
        // Skip logical operators
        if (Object.keys(LogicalOperators).includes(key)) {
          processObject(value);
          continue;
        }

        // population attribute returns ids
        if (key.startsWith('population_attribute__')) {
          if (value && typeof value === 'object' && 'id' in value) {
            keys.add(value.id);
          }
          continue;
        }

        if (typeof value === 'object' && value !== null) {
          // Check if it's a query operator object (e.g., { $eq: "value" })
          const isQueryOperator = Object.keys(value).every(k => Object.values(FilterOperators).includes(k));
          if (isQueryOperator) {
            keys.add(key);
          } else {
            processObject(value);
          }
        }
      }
    }
  }

  processObject(filters);
  return Array.from(keys);
}

export function hasExcludeLabel(filters) {
  if (!filters || typeof filters !== 'object') {
    return false;
  }

  if ('label_ids' in filters &&
      typeof filters.label_ids === 'object' &&
      '$not_in' in filters.label_ids) {
    return true;
  }

  return Object.values(filters).some(value => {
    if (value && typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.some(hasExcludeLabel);
      }
      return hasExcludeLabel(value);
    }
    return false;
  });
};

export function hasOrFiltering(filters) {
  if (!filters || typeof filters !== 'object') {
    return false;
  }

  if (LogicalOperators.OR in filters) {
    return true;
  }

  return Object.values(filters).some(value => {
    if (value && typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.some(hasOrFiltering);
      }
      return hasOrFiltering(value);
    }
    return false;
  });
};

export function hasFilterGroup(filters) {
  if (!filters || typeof filters !== 'object') {
    return false;
  }

  const keys = Object.keys(filters);

  if (!hasTopLevelOperator(keys)) {
    return false;
  }

  const isLogicalOperator = (obj) => {
    return (
      obj &&
      typeof obj === 'object' &&
      (LogicalOperators.AND in obj || LogicalOperators.OR in obj)
    );
  };

  // For each top-level AND/OR operator, check if any of its children contain AND/OR
  return keys.some((key) => {
    if (key === LogicalOperators.AND || key === LogicalOperators.OR) {
      const conditions = filters[key];

      if (!Array.isArray(conditions)) {
        return false;
      }

      // Check if any of the conditions contain an AND/OR operator
      return conditions.some(isLogicalOperator);
    }
    return false;
  });
}

export function filterTrackingData(filters) {
  return {
    fields: getOperatorKeys(filters),
    has_filter_group: hasFilterGroup(filters),
    has_exclude_label: hasExcludeLabel(filters),
    has_or_filtering: hasOrFiltering(filters),
  };
}

export function encodedParticipantSearchFilter({ filterRules, projectFilters, operators }) {
  const searchFilter = {
    filter_rules: filterRules,
    project_filters: projectFilters,
    operators: transformOperatorKeysAndValues(operators),
  };

  const cleansedFilter = Object.keys(searchFilter).reduce((acc, key) => {
    const value = searchFilter[key];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});

  if (Object.values(cleansedFilter).length === 0) {
    // This will probably have to get tweaked once this method is more robust
    // but for now if there are no relevant pieces of data, return nothing
    return undefined;
  }

  return QueryString.base64Encode(cleansedFilter);
}

function hasTopLevelOperator(filterKeys) {
  return filterKeys.some(
    (key) => key === LogicalOperators.AND || key === LogicalOperators.OR,
  );
}
